import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://2eaeb7ab8ea923b6fbfbe027a3c1c716@o4506189644431360.ingest.sentry.io/4506233921536000",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        "devmc.terragonltd.com",
        /devmc.terragonltd.com\/.*/,
      ],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0, 
  replaysSessionSampleRate: 1.0, 
  replaysOnErrorSampleRate: 1.0, 
});

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
